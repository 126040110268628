function getModifierPrice ({price, priceIf}) {
  let priceDisplay
  if (Array.isArray(priceIf)) {
    let result = priceIf.find(({match}) => customerPicks[match])
    priceDisplay = result && result.price || 0
  } else {
    priceDisplay = price || 0
  }
  return priceDisplay
}

function getModifierSummary ({modifiers, customerPicks}) {
  let totalModifierPrice = 0
  let totalModifierCount = 0
  modifiers.forEach(mod => {
    let picked = customerPicks[mod.id]
    if (picked) {
      let quantity = isNaN(Number(picked.quantity)) ? 1 : picked.quantity
      let pMPrice = getModifierPrice(mod) * quantity
      totalModifierPrice += pMPrice
      totalModifierCount += quantity
    }

  })
  return {totalModifierCount, totalModifierPrice}
}

export function validateCustomerPicksForProduct (product, cart) {
  // const {customerPicks={}, modgroups=[]} = product
  const customerPicks = product.customerPicks || {}
  const modgroups     = product.modgroups     || []
  const resultsMap = {}
  const resultsArray = []

  modgroups.forEach(({id: modgroupId, required, minCount, minPrice, maxCount, modifiers, type}) => {
    // console.log({maxCount})
    if (!modifiers || (modifiers.length === 0)) { return } // need to contain modifiers
    if (required) {
      const pickedModifier = modifiers.find(({id: modifierId}) => {
        return customerPicks[modifierId]
      })
      if (!pickedModifier) {
        // customer did not make a requried selection
        const errorObj = { modgroupId, message: 'This selection is required' }
        resultsMap[modgroupId] = errorObj
        resultsArray.push(errorObj)
      }
    }
    if (minCount) {
      const {totalModifierCount, totalModifierPrice} = getModifierSummary({modifiers, customerPicks, type})
      console.log({totalModifierCount, totalModifierPrice})
      if (totalModifierCount < minCount) {
        const errorObj = { modgroupId, message: `Need at least ${minCount} picks`}
        resultsMap[modgroupId] = errorObj
        resultsArray.push(errorObj)
      }
    }

    if (maxCount) {
      const {totalModifierCount, totalModifierPrice} = getModifierSummary({modifiers, customerPicks, type})
      console.log({totalModifierCount, totalModifierPrice})
      if (totalModifierCount > Number(maxCount)) {

        const errorObj = { modgroupId, message: `Exceeded max pick of ${maxCount}`}
        resultsMap[modgroupId] = errorObj
        resultsArray.push(errorObj)
      }
    }

    if (minPrice) {
      const {totalModifierCount, totalModifierPrice} = getModifierSummary({modifiers, customerPicks, type})
      if (Number(totalModifierPrice) < Number(minPrice)) {
        const errorObj = { modgroupId, message: `Minimum Price: $${min}`}
        resultsMap[modgroupId] = errorObj
        resultsArray.push(errorObj)
      }
    }



  })

  // check quantity
  if (cart) {
    if (product.name && (product.name.en.indexOf('pw1') !== -1)) {
      console.log('checking quantity', product.name.en)
      let existingTacos = 0
      cart.state.products.forEach(({ id, name, quantity}) => {
        if (product.id === id) {return}
        if (name && name.en === product.name.en) {
          existingTacos += quantity
        }
      })
      const totalTacos = ((Number(product.quantity) || 1) + existingTacos)
      console.log(totalTacos)
      if (totalTacos > 5) {
        const errorObj = { message: `You cannot have more than 5 ${product.name.en}s in your cart`}
        resultsMap['_quantity'] = errorObj
        resultsArray.push(errorObj)
      }
    }

    if (product.name && (product.name.en.indexOf('(limit 5)') !== -1)) {
      console.log('checking quantity', product.name.en)
      let existingTacos = 0
      cart.state.products.forEach(({ id, name, quantity}) => {
        if (product.id === id) {return}
        
        existingTacos += quantity
        
      })
      const totalTacos = ((Number(product.quantity) || 1) + existingTacos)
      console.log(totalTacos)
      if (totalTacos > 5) {
        const errorObj = { message: `You can not have more than 5 promo tacos in your cart.`}
        resultsMap['_quantity'] = errorObj
        resultsArray.push(errorObj)
      }
    }

    //pizza
    if (product.name && (product.name.en.indexOf('(limit 1)') !== -1)) {
      console.log('checking quantity', product.name.en)
      let existingProducts = 0
      cart.state.products.forEach(({ id, name, quantity}) => {
        if (product.id === id) {return}
        if (name && name.en === product.name.en) {
          existingProducts += quantity
        }
      })
      const totalProduct = ((Number(product.quantity) || 1) + existingProducts)
      console.log(totalProduct)
      if (totalProduct > 1) {
        const errorObj = { message: `You cannot have more than 1 ${product.name.en}s in your cart`}
        resultsMap['_quantity'] = errorObj
        resultsArray.push(errorObj)
      }
    }

    if (product.name && (product.name.en.indexOf('pw2') !== -1)) {
      console.log('checking quantity', product.name.en)
      let existingWings = 0
      let hasAFlavor = false
      cart.state.products.forEach(({ id, name, quantity}) => {
        if (product.id === id) {return}
        if (name && name.en === product.name.en) {
          existingWings += quantity
          hasAFlavor = true
        }
      })
      const totalWings = ((Number(product.quantity) || 1) + existingWings)
      console.log(totalWings)
      if ((totalWings > 10) || (hasAFlavor)) {
        const errorObj = { message: `You cannot have more than 10 ${product.name.en}s in your cart. Only 1 flavor alowed per order.`}
        resultsMap['_quantity'] = errorObj
        resultsArray.push(errorObj)
      }
    }

    
  }

  return { resultsMap, resultsArray, validated: (resultsArray.length === 0) }
  // check if
}
