import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'

import ThemeContext from '../../Context/ThemeContext'

import { Subscribe } from 'unstated'
import {injectCartContainer} from '../../react/CartContainer'
import {injectShopContainer} from '../../react/ShopDataContainer'
import LogoBar from "./svg/LogoBar"
// import {injectCustomComponents} from '../CustomComponentsProvider'
// import {getRestaurantId} from '../../utils'

// import Image from '../../react/Image'
// import makeCloudinaryUrlForPhoto from './makeCloudinaryUrlForPhoto'

function CartMinimized ({cart, shop, settings}) {
  const {themeName} = useContext(ThemeContext)

  if (!shop) {return null}
  let items = cart.state.products
  let numberOfItems = items.length || 0
  let {
    logo,
  } = shop
  // console.log(shop.name)
  // const logoImage = makeCloudinaryUrlForPhoto(logo, {height: 200})
  const cashback = cart.state.cashback
  const showCashback = Number(cashback) && (cashback > 0)

  const logoURL = (themeName === 'dark') ?
  "https://afag.imgix.net/china-buffet-takeout-delivery/logo.png?h=100&auto=format" :
  "https://afag.imgix.net/china-buffet-takeout-delivery/logo.png?h=100&auto=format"
  return (
    <div className="cart-wrapper">

      <div className="cart minimized">
        <div className="home-link-wrapper">
          <Link to="/" className="home-link">
            {/* <img
              className="nav-bar-logo"
              src={logoURL}
              alt="noho ramen"
            /> */}
            <LogoBar></LogoBar>
            {/* hi */}
            {/* <span className="text-logo">{!logo && shop.name && shop.name.en}</span> */}
          </Link>

        </div>



        <div className="tool-bar">
          {/* {showCashback ? <span className="cashback-amount">
            ${cashback / 100}
          </span> : null} */}
          <Link
            title="Shopping Cart"
            to={{pathname: "/checkout", state: {fromMini: true}}}
            className="checkout-button button">cart ({numberOfItems})</Link>
        </div>



      </div>
    </div>
  )
}




const CartIcon = () => <svg xmlns="http://www.w3.org/2000/svg" className="cart-icon" viewBox="0 0 24 24" version="1" width="50" height="50">
  <path d="M20 20v-8h2L12 3 2 12h2v8l1 1h5v-7h4v7h5l1-1z"/>
</svg>

export default (injectCartContainer(injectShopContainer(CartMinimized)))
