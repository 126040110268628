import React, { useEffect } from 'react';

const OpenTableWidget = ({ rid }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.opentable.com/widget/reservation/loader?rid=' + rid + '&domain=com&type=standard&theme=wide&lang=en&overlay=false&iframe=true';
    script.async = true;

    document.getElementById('opentable-widget').appendChild(script);

    return () => {
      document.getElementById('opentable-widget').removeChild(script);
    };
  }, [rid]);

  return (
    <div id="opentable-widget" />
  );
};

export default OpenTableWidget;