
import * as Components from "./Kaiju"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "kaiju"
}

